import {NftVersion, defaultCovers} from '../arks-list/core/_models'

const isImage = (url: string) => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)
}

const isVideo = (url: string) => {
  return /\.(mp4|mkv|mpeg)$/.test(url)
}

const isAudio = (url: string) => {
  return /\.(wav|ogg|mp3|aac|m4a|flac)$/.test(url)
}

const isDoc = (url: string) => {
  return /\.(doc|docx|pdf|rtf|txt)$/.test(url)
}

const ArkPreview = ({version}: {version: NftVersion}) => {
  const ifImage = isImage(version.og_file_link)
  const ifVideo = isVideo(version.og_file_link)
  const ifAudio = isAudio(version.og_file_link)
  const ifDoc = isDoc(version.og_file_link)

  return (
    <div>
      {ifImage && (
        <img
          className='border object-fit-contain mb-6'
          width='100%'
          height='400px'
          src={version.og_file_link}
          alt='ark'
        />
      )}

      {ifVideo && <video controls width='100%' height='400px' src={version.og_file_link}></video>}

      {ifAudio && (
        <div>
          {(version.thumbnail || version.cover_image) && (
            <img
              className='border object-fit-contain mb-6'
              width='100%'
              height='400px'
              src={version.thumbnail || version.cover_image}
              alt='ark'
            />
          )}
          <audio controls src={version.og_file_link}></audio>
        </div>
      )}

      {ifDoc && (
        <div>
          <a href={version.og_file_link} target='_blank' rel='noopener noreferrer'>
            <img
              className='border object-fit-contain mb-6'
              width='100%'
              height='400px'
              src={defaultCovers['document']}
              alt='ark'
            />
          </a>
        </div>
      )}
    </div>
  )
}

export {ArkPreview}
