/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

type DashboardResponse = {
  message: string
  data: {
    userCount?: number
    nftCount?: number
    enquiryCount?: number
  }
}

const DashboardPage: FC = () => {
  const [userCount, setUserCount] = useState(0)
  const [nftCount, setNftCount] = useState(0)
  const [enquiryCount, setEnquiryCount] = useState(0)

  useEffect(() => {
    axios.get<DashboardResponse>(`${API_URL}/dashboard`).then((res) => {
      if (res.data.data.userCount) {
        setUserCount(res.data.data.userCount)
      }
      if (res.data.data.nftCount) {
        setNftCount(res.data.data.nftCount)
      }
      if (res.data.data.enquiryCount) {
        setEnquiryCount(res.data.data.enquiryCount)
      }
    })
  }, [])

  return (<>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className='col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-md-5 mb-xl-10'>
        <div
          className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end bg-success`}
        >
          <div className='card-header pt-5 pb-4'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{userCount}</span>
              <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>Total Users</span>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-md-5 mb-xl-10'>
        <div
          className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end bg-primary`}
        >
          <div className='card-header pt-5 pb-4'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{nftCount}</span>
              <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>Total NFTs</span>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-md-5 mb-xl-10'>
        <div
          className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end bg-danger`}
        >
          <div className='card-header pt-5 pb-4'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{enquiryCount}</span>
              <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>Total Enquiries</span>
            </div>
          </div>
        </div>
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}
  </>)
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
