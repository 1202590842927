/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Ark} from '../../core/_models'
import {Link} from 'react-router-dom'

type Props = {
  ark: Ark
}

const ArkInfoCell: FC<Props> = ({ark}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <Link to={`/arks/${ark.id}`}>{ark.latest_version.title}</Link>
    </div>
  </div>
)

export {ArkInfoCell}
