import moment from 'moment'
import {FC} from 'react'

type Props = {
  date?: string
}

const FAQUploadedOnCell: FC<Props> = ({date}) => (
  <div className=''>{moment(date).format('DD-MM-YYYY HH:mm:ss')}</div>
)

export {FAQUploadedOnCell}
