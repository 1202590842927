import {Link} from 'react-router-dom'

const FAQsListToolbar = () => {
  return (
    <div className='d-flex justify-content-end' data-kt-faq-table-toolbar='base'>
      <Link to='/faqs/create' className='btn btn-primary me-3'>
        Add new
      </Link>
    </div>
  )
}

export {FAQsListToolbar}
