// @ts-nocheck
import {Column} from 'react-table'
import {EnquiryInfoCell} from './EnquiryInfoCell'
import {EnquiryEmailCell} from './EnquiryEmailCell'
import {EnquiryActionsCell} from './EnquiryActionsCell'
import {EnquirySelectionCell} from './EnquirySelectionCell'
import {EnquiryCustomHeader} from './EnquiryCustomHeader'
import {EnquirySelectionHeader} from './EnquirySelectionHeader'
import {Enquiry} from '../../core/_models'
import {EnquiryJoinDateCell} from './EnquiryJoinDateCell'
import {EnquiryMessageCell} from './EnquiryMessageCell'

const enquiriesColumns: ReadonlyArray<Column<Enquiry>> = [
  {
    Header: (props) => <EnquirySelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <EnquirySelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <EnquiryCustomHeader tableProps={props} title='User Name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <EnquiryInfoCell enquiry={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EnquiryCustomHeader tableProps={props} title='Email' className='min-w-125px' />
    ),
    id: 'email',
    Cell: ({...props}) => <EnquiryEmailCell email={props.data[props.row.index].email} />,
  },
  {
    Header: (props: PropsInterface) => (
      <EnquiryCustomHeader tableProps={props} title='Message' className='min-w-125px' />
    ),
    id: 'text',
    Cell: ({...props}) => <EnquiryMessageCell enquiry={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EnquiryCustomHeader tableProps={props} title='Created on' className='min-w-125px' />
    ),
    accessor: 'created_at',
    Cell: ({...props}) => <EnquiryJoinDateCell date={props.data[props.row.index].created_at} />,
  },
  {
    Header: (props) => (
      <EnquiryCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <EnquiryActionsCell id={props.data[props.row.index].id} />,
  },
]

export {enquiriesColumns}
