/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {FAQ} from '../../core/_models'

type Props = {
  faq: FAQ
}

const FaqAnswerCell: FC<Props> = ({faq}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span
        style={{
          whiteSpace: 'nowrap',
          width: '250px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {faq.answer}
      </span>
    </div>
  </div>
)

export {FaqAnswerCell}
