import {useEffect, useState} from 'react'
import {useListView} from '../core/ListViewProvider'
import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {useQueryResponse, useQueryResponseData} from '../core/QueryResponseProvider'
import {Enquiry} from '../core/_models'
import {useMutation, useQueryClient} from 'react-query'
import {archiveEnquiry} from '../core/_requests'

const EnquiryViewModal = () => {
  const {itemIdToView, setItemIdToView} = useListView()
  const enquiries = useQueryResponseData()
  const [enquiry] = useState<Enquiry | undefined>(enquiries.find((e) => e.id === itemIdToView))
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const archive = useMutation(() => archiveEnquiry(itemIdToView), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.ENQUIRY_LIST}-${query}`])
    },
  })

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>View enquiry</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => setItemIdToView(undefined)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-4 mx-xl-8'>
              <label className='fw-bolder'>User name</label>
              <p>{enquiry?.name}</p>

              <label className='fw-bolder'>Email address</label>
              <p>{enquiry?.email}</p>

              <label className='fw-bolder'>Message</label>
              <p style={{whiteSpace: 'pre-line'}}>{enquiry?.text}</p>
            </div>
            {/* end::Modal body */}

            <div className='modal-footer mx-4 mx-xl-8'>
              <button
                type='button'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                disabled={isSubmitting}
                onClick={async () => {
                  setIsSubmitting(true)
                  await archive.mutateAsync()
                  setIsSubmitting(false)
                  setTimeout(() => {
                    setItemIdToView(undefined)
                  }, 200)
                }}
              >
                {!isSubmitting && <span className='indicator-label'>Archive</span>}
                {isSubmitting && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>

              <button
                type='button'
                className='btn btn-secondary'
                onClick={() => setItemIdToView(undefined)}
              >
                Close
              </button>
            </div>
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {EnquiryViewModal}
