import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Ark, ArksQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const ARK_URL = `${API_URL}/nft`
const GET_ARKS_URL = `${API_URL}/nft/list`

// axios.defaults.

const getArks = (query: string): Promise<ArksQueryResponse> => {
  return axios.get(`${GET_ARKS_URL}?${query}`).then((d: AxiosResponse<ArksQueryResponse>) => d.data)
}

const getArkById = (id: ID): Promise<Ark | undefined> => {
  return axios
    .get(`${ARK_URL}/${id}`)
    .then((response: AxiosResponse<Response<Ark>>) => response.data)
    .then((response: Response<Ark>) => response.data)
}

const createArk = (ark: Ark): Promise<Ark | undefined> => {
  return axios
    .put(ARK_URL, ark)
    .then((response: AxiosResponse<Response<Ark>>) => response.data)
    .then((response: Response<Ark>) => response.data)
}

const updateArk = (ark: Ark): Promise<Ark | undefined> => {
  return axios
    .post(`${ARK_URL}/${ark.id}`, ark)
    .then((response: AxiosResponse<Response<Ark>>) => response.data)
    .then((response: Response<Ark>) => response.data)
}

const deleteArk = (arkId: ID): Promise<void> => {
  return axios.delete(`${ARK_URL}/${arkId}`).then(() => {})
}

const deleteSelectedArks = (arkIds: Array<ID>): Promise<void> => {
  const requests = arkIds.map((id) => axios.delete(`${ARK_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getArks,
  deleteArk,
  deleteSelectedArks,
  getArkById,
  createArk,
  updateArk,
  GET_ARKS_URL,
  ARK_URL,
}
