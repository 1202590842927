import {useListView} from '../../core/ListViewProvider'
import {ArksListToolbar} from './ArkListToolbar'
import {ArksListGrouping} from './ArksListGrouping'
import {ArksListSearchComponent} from './ArksListSearchComponent'

const ArksListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ArksListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ArksListGrouping /> : <ArksListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ArksListHeader}
