import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ArksListHeader} from './components/header/ArksListHeader'
import {ArksTable} from './table/ArksTable'
import {KTCard} from '../../../../../_metronic/helpers'

const ArksList = () => {
  return (
    <>
      <KTCard>
        <ArksListHeader />
        <ArksTable />
      </KTCard>
    </>
  )
}

const ArksListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ArksList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ArksListWrapper}
