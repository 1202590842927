import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import EditTermsAndConditions from './EditTermsAndConditions'

const usersBreadcrumbs: Array<PageLink> = []

const TermsAndConditions = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Terms and conditions</PageTitle>
              <EditTermsAndConditions />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default TermsAndConditions
