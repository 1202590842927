import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'

const SidebarLogo = () => {
  const {config} = useLayout()

  return (
    <div className='app-sidebar-logo px-6' id='kt_app_sidebar_logo'>
      <Link to='/dashboard'>
        {config.layoutType === 'dark-sidebar' ? (
          <div className='d-flex align-items-end'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/ark-logo.png')}
              className='h-25px app-sidebar-logo-default'
              style={{ minHeight: '32px' }}
            />
            <span className='text-white ms-4' style={{ lineHeight: 1, fontSize: '30px' }}>Ark</span>
          </div>
        ) : (
          <>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/ark-logo.png')}
              className='h-25px app-sidebar-logo-default theme-light-show'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/ark-logo.png')}
              className='h-25px app-sidebar-logo-default theme-dark-show'
            />
            ARK
          </>
        )}

        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/ark-logo.png')}
          className='h-20px app-sidebar-logo-minimize'
        />
      </Link>
    </div>
  )
}

export {SidebarLogo}
