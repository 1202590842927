import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import ArksPage from '../modules/apps/ark-management/ArksPage'
import EnquiriesPage from '../modules/apps/enquiry-management/EnquiriesPage'
import FAQsPage from '../modules/apps/faq-management/FAQsPage'
import TermsAndConditions from '../modules/apps/editable-page/TermsAndConditions'
import PrivacyPolicy from '../modules/apps/editable-page/PrivacyPolicy'

const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='arks/*'
          element={
            <SuspensedView>
              <ArksPage />
            </SuspensedView>
          }
        />
        <Route
          path='enquiries/*'
          element={
            <SuspensedView>
              <EnquiriesPage />
            </SuspensedView>
          }
        />
        <Route
          path='faqs/*'
          element={
            <SuspensedView>
              <FAQsPage />
            </SuspensedView>
          }
        />

        <Route
          path='edit/terms-and-conditions'
          element={
            <SuspensedView>
              <TermsAndConditions />
            </SuspensedView>
          }
        />

        <Route
          path='edit/privacy-policy'
          element={
            <SuspensedView>
              <PrivacyPolicy />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
