/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {User} from '../../../../user-management/users-list/core/_models'
import {Link} from 'react-router-dom'

type Props = {
  user: User
}

const ArkUserCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <Link to={`/users/${user.id}`}>
        <span>{user.full_name}</span>
      </Link>
    </div>
  </div>
)

export {ArkUserCell}
