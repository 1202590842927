/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {FAQ} from '../../core/_models'
import {Link} from 'react-router-dom'

type Props = {
  faq: FAQ
}

const FAQInfoCell: FC<Props> = ({faq}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <Link to={`/faqs/${faq.id}`}>{faq.question}</Link>
    </div>
  </div>
)

export {FAQInfoCell}
