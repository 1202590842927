import {FC} from 'react'
import {Ark} from '../../core/_models'

type Props = {
  ark: Ark
}

const ArkInteractionsCell: FC<Props> = ({ark}) => (
  <div>
    <span title='like count'>
      {ark.likes_count} <i className='bi bi-heart-fill'></i>
    </span>
    ,{' '}
    <span title='share count'>
      {ark.shares_count} <i className='bi bi-share-fill'></i>
    </span>
  </div>
)

export {ArkInteractionsCell}
