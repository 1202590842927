import {useFormik} from 'formik'
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'

const faqSchema = Yup.object().shape({
  question: Yup.string().required('Question is required'),
  answer: Yup.string().required('Answer is required'),
})

const initialValues = {
  question: '',
  answer: '',
}

const CreateFAQ = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: faqSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await axios.post('/faq/create', values)
        setLoading(false)
        navigate('/faqs/all')
      } catch (error) {
        console.error(error)
        setStatus('Something went wrong')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body'>
          <form className='row' onSubmit={formik.handleSubmit} noValidate>
            <div className='col-12 col-lg-8 mb-4'>
              <div className='form-group'>
                <label htmlFor='question' className='form-label'>
                  Question :
                </label>
                <input
                  id='question'
                  type='text'
                  placeholder='Question'
                  {...formik.getFieldProps('question')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.question && formik.errors.question},
                    {
                      'is-valid': formik.touched.question && !formik.errors.question,
                    }
                  )}
                />

                {formik.touched.question && formik.errors.question && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.question}</span>
                  </div>
                )}
              </div>
            </div>
            <div className='col-12 col-lg-8 mb-4'>
              <div className='form-group'>
                <label htmlFor='answer' className='form-label'>
                  Answer :
                </label>

                <textarea
                  id='answer'
                  placeholder='Write an answer here...'
                  {...formik.getFieldProps('answer')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.answer && formik.errors.answer},
                    {
                      'is-valid': formik.touched.answer && !formik.errors.answer,
                    }
                  )}
                  cols={30}
                  rows={10}
                ></textarea>

                {formik.touched.answer && formik.errors.answer && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.answer}</span>
                  </div>
                )}
              </div>
            </div>

            {formik.status && <p className='text-danger'>{formik.status}</p>}

            <div className='col-12 col-lg-8'>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'> Add FAQ</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export {CreateFAQ}
