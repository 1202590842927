import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {EnquiriesListHeader} from './components/header/EnquiriesListHeader'
import {EnquiriesTable} from './table/EnquiriesTable'
import {KTCard} from '../../../../../_metronic/helpers'
import {EnquiryViewModal} from './enquiry-view-modal/EnquiryViewModal'

const EnquiriesList = () => {
  const {itemIdToView} = useListView()

  return (
    <>
      <KTCard>
        <EnquiriesListHeader />
        <EnquiriesTable />
      </KTCard>
      {itemIdToView && <EnquiryViewModal />}
    </>
  )
}

const EnquiriesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <EnquiriesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {EnquiriesListWrapper}
