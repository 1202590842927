// @ts-nocheck
import {Column} from 'react-table'
import {FAQInfoCell} from './FAQInfoCell'
// import {FAQSelectionCell} from './FAQSelectionCell'
import {FAQCustomHeader} from './FAQCustomHeader'
// import {FAQSelectionHeader} from './FAQSelectionHeader'
import {FAQ} from '../../core/_models'
import {FAQUploadedOnCell} from './FAQUploadedOnCell'
import {FAQActionsCell} from './FAQActionsCell'
import {FaqAnswerCell} from './EnquiryMessageCell'

interface PropsInterface {
  data: FAQ[]
}

const columns: ReadonlyArray<Column<FAQ>> = [
  // {
  //   Header: (props: PropsInterface) => <FAQSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}: PropsInterface) => <FAQSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props: PropsInterface) => (
      <FAQCustomHeader tableProps={props} title='Question' className='min-w-125px' />
    ),
    id: 'question',
    Cell: ({...props}: PropsInterface) => <FAQInfoCell faq={props.data[props.row.index]} />,
  },
  {
    Header: (props: PropsInterface) => (
      <FAQCustomHeader tableProps={props} title='Answer' className='min-w-125px max-w-125px' />
    ),
    id: 'answer',
    Cell: ({...props}) => <FaqAnswerCell faq={props.data[props.row.index]} />,
  },
  {
    Header: (props: PropsInterface) => (
      <FAQCustomHeader tableProps={props} title='Added on' className='min-w-125px' />
    ),
    accessor: 'created_at',
    Cell: ({...props}: PropsInterface) => (
      <FAQUploadedOnCell date={props.data[props.row.index].created_at} />
    ),
  },
  {
    Header: (props) => (
      <FAQCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <FAQActionsCell faq={props.data[props.row.index]} />,
  },
]

export {columns}
