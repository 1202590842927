export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  if (current.includes('/edit/terms-and-conditions') || current.includes('/edit/privacy-policy')) {
    if (current === url) {
      return true
    } else {
      return false
    }
  }

  if (current === url || (current.split('/').length > 1 && url.includes(current.split('/')[1]))) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}
