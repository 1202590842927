/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/svg/shapes/speedometer2.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Listings</span>
        </div>
      </div>

      <SidebarMenuItem to='/users/all' icon='/media/svg/shapes/people.svg' title='Users' />

      <SidebarMenuItem to='/arks/all' icon='/media/svg/shapes/boxes.svg' title='Arks' />

      <SidebarMenuItem
        to='/enquiries/all'
        icon='/media/svg/shapes/envelope.svg'
        title='Enquiries'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Settings</span>
        </div>
      </div>

      <SidebarMenuItem to='/faqs/all' icon='/media/svg/shapes/question-square.svg' title='FAQs' />

      <SidebarMenuItem
        to='/edit/terms-and-conditions'
        icon='/media/icons/duotune/files/fil003.svg'
        title='Terms and conditions'
      />

      <SidebarMenuItem
        to='/edit/privacy-policy'
        icon='/media/icons/duotune/files/fil003.svg'
        title='Privacy policy'
      />
    </>
  )
}

export {SidebarMenuMain}
