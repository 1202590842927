import {Response} from '../../../../../../_metronic/helpers'
import {User} from '../../../user-management/users-list/core/_models'

export enum ShareType {
  public = 'public',
  share = 'share',
  private = 'private',
}

export enum Categories {
  video = 'video',
  music = 'music',
  design = 'design',
  image = 'image',
  code = 'code',
  document = 'document',
}

export type NftVersion = {
  id: string
  version: string
  nft_id: string
  user_id: string
  title: string
  description: string
  file: string
  og_file_link: string
  cover_image: string
  thumbnail: string
  token_id: string
  created_at: string
  collaborators: User[]
}

export const defaultCovers = {
  [Categories.video]:
    'https://ark-space.sfo3.cdn.digitaloceanspaces.com/defaults/ic_thumbnail_video.png',
  [Categories.document]:
    'https://ark-space.sfo3.cdn.digitaloceanspaces.com/defaults/ic_thumbnail_document.png',
  [Categories.image]:
    'https://ark-space.sfo3.cdn.digitaloceanspaces.com/defaults/ic_thumbnail_image.png',
  [Categories.code]:
    'https://ark-space.sfo3.cdn.digitaloceanspaces.com/defaults/ic_thumbnail_code.png',
  [Categories.music]:
    'https://ark-space.sfo3.cdn.digitaloceanspaces.com/defaults/ic_thumbnail_audio.png',
  [Categories.design]:
    'https://ark-space.sfo3.cdn.digitaloceanspaces.com/defaults/ic_thumbnail_design.png',
}

export type Ark = {
  id: string
  version: string
  user_id: string
  user: User
  share_type: ShareType
  category: Categories
  created_at: string
  collaborators: User[]
  latest_version: NftVersion
  likes_count: number
  shares_count: number
  interactions_count: number
  collab_requests_count: number
  collaborators_count: number
}

export type ArksQueryResponse = Response<Array<Ark>>
