import {FC} from 'react'
import {Categories} from '../../core/_models'

type Props = {
  category: Categories
}

const ArkCategoryCell: FC<Props> = ({category}) => (
  <div className=''>{category === Categories.music ? 'audio' : category}</div>
)

export {ArkCategoryCell}
