/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import classnames from 'classnames'
import {DOTS, usePagination} from '../hooks/usePagination'

const Pagination = (props: {
  onPageChange: Function
  totalCount: number
  siblingCount: number
  currentPage: number
  pageSize: number
  className: string
}) => {
  const {onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className} = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  let lastPage = paginationRange ? paginationRange[paginationRange?.length - 1] : 1

  const onNext = () => {
    if (currentPage < parseInt(String(lastPage))) {
      onPageChange(currentPage + 1)
    }
  }

  const onPrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  }

  return (
    <ul className={classnames('pagination', {[className]: className})}>
      <li
        className={classnames('page-item previous', {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <a className={classnames('page-link page-text')} style={{cursor: 'pointer'}}>
          Previous
        </a>
      </li>
      {paginationRange?.map((pageNumber, idx) => {
        if (pageNumber === DOTS) {
          return (
            <li key={pageNumber + idx} className='page-item dots'>
              &#8230;
            </li>
          )
        }

        return (
          <li
            key={pageNumber}
            className={classnames('page-item', {
              active: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            <a className={classnames('page-link')} style={{cursor: 'pointer'}}>
              {pageNumber}
            </a>
          </li>
        )
      })}
      <li
        className={classnames('page-item', {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <a className={classnames('page-link page-text')} style={{cursor: 'pointer'}}>
          Next
        </a>
      </li>
    </ul>
  )
}

export default Pagination
