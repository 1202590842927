import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {FAQ, FAQsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const FAQ_URL = `${API_URL}/faq`

// axios.defaults.

const getFAQs = (query: string): Promise<FAQsQueryResponse> => {
  return axios.get(`${FAQ_URL}/list?${query}`).then((d: AxiosResponse<FAQsQueryResponse>) => d.data)
}

const getFAQById = (id: ID): Promise<FAQ | undefined> => {
  return axios
    .get(`${FAQ_URL}/${id}`)
    .then((response: AxiosResponse<Response<FAQ>>) => response.data)
    .then((response: Response<FAQ>) => response.data)
}

const createFAQ = (fAQ: FAQ): Promise<FAQ | undefined> => {
  return axios
    .put(FAQ_URL, fAQ)
    .then((response: AxiosResponse<Response<FAQ>>) => response.data)
    .then((response: Response<FAQ>) => response.data)
}

const updateFAQ = (fAQ: FAQ): Promise<FAQ | undefined> => {
  return axios
    .post(`${FAQ_URL}/${fAQ.id}`, fAQ)
    .then((response: AxiosResponse<Response<FAQ>>) => response.data)
    .then((response: Response<FAQ>) => response.data)
}

const deleteFAQ = (fAQId: ID): Promise<void> => {
  return axios.delete(`${FAQ_URL}/${fAQId}`).then(() => {})
}

const deleteSelectedFAQs = (fAQIds: Array<ID>): Promise<void> => {
  const requests = fAQIds.map((id) => axios.delete(`${FAQ_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getFAQs, deleteFAQ, deleteSelectedFAQs, getFAQById, createFAQ, updateFAQ, FAQ_URL}
