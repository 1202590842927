import {useListView} from '../../core/ListViewProvider'
import {FAQsListToolbar} from './FAQListToolbar'
import {FAQsListGrouping} from './FAQsListGrouping'
import {FAQsListSearchComponent} from './FAQsListSearchComponent'

const FAQsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <FAQsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <FAQsListGrouping /> : <FAQsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {FAQsListHeader}
