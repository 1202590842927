import {useEffect, useState} from 'react'
import {Ark} from '../arks-list/core/_models'
import {ARK_URL} from '../arks-list/core/_requests'
import axios from 'axios'
import moment from 'moment'
import {Link} from 'react-router-dom'

interface Interaction {
  created_at: string
  activity_type: string
  from_user: {
    id: string
    first_name: string
    last_name: string
    full_name: string
  }
  nft_id: string
  id: string
}

const ArkInteractionlist = (props: {ark: Ark}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<Interaction[]>([])

  const fetchData = async () => {
    setIsLoading(true)

    const response = await axios.get(`${ARK_URL}/${props.ark.id}/interactions?page=1&limit=10000`)

    if (response.data.data) {
      setData(response.data.data)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{maxHeight: '400px', overflow: 'auto'}}>
      <table className='table table-bordered border'>
        <thead>
          <tr>
            <th>User</th>
            <th>Interaction</th>
            <th>Date/Time</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={4} className='text-center'>
                Loading...
              </td>
            </tr>
          ) : data.length ? (
            data.map((v, i) => {
              return (
                <tr key={v.id}>
                  <td>
                    <Link to={`/users/${v.from_user.id}`}>{v.from_user.full_name}</Link>
                  </td>
                  <td>{v.activity_type}</td>
                  <td>{moment(v.created_at).format('DD-MM-YYYY HH:mm:ss')}</td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={4} className='text-center'>
                No items
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export {ArkInteractionlist}
