/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {archiveEnquiry} from '../../core/_requests'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

type Props = {
  id: ID
}

const EnquiryActionsCell: FC<Props> = ({id}) => {
  const {setItemIdToView} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  const MySwal = withReactContent(Swal)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openViewModal = () => {
    setItemIdToView(id)
  }

  const deleteItem = useMutation(
    async () => {
      await new Promise<void>((resolve, _reject) => {
        MySwal.fire({
          title: <p className='mb-0'>Are you sure you want to archive this enquiry?</p>,
          confirmButtonText: 'Yes',
          showDenyButton: true,
          denyButtonText: 'No',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger me-3',
            denyButton: 'btn btn-secondary',
          },
        }).then(({isConfirmed}) => {
          if (isConfirmed) {
            archiveEnquiry(id).then(() => {
              queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
              resolve()
            })
          }
        })
      })
    },
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {
        // ✅ update detail view directly
        queryClient.invalidateQueries([`${QUERIES.ENQUIRY_LIST}-${query}`])
      },
    }
  )

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openViewModal}>
            View
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-enquiries-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            Archive
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {EnquiryActionsCell}
