import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {FAQsListWrapper} from './faq-list/FAQList'
import {EditFAQ} from './faq-edit/EditFAQ'
import {CreateFAQ} from './faq-create/CreateFAQ'

const FAQsBreadcrumbs: Array<PageLink> = [
  {
    title: 'FAQ Management',
    path: '/faqs',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const FAQsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/all'
          element={
            <>
              <PageTitle breadcrumbs={FAQsBreadcrumbs}>FAQs list</PageTitle>
              <FAQsListWrapper />
            </>
          }
        />
        <Route
          path='/create'
          element={
            <>
              <PageTitle breadcrumbs={FAQsBreadcrumbs}>Add FAQ</PageTitle>
              <CreateFAQ />
            </>
          }
        />
        <Route
          path='/:id'
          element={
            <>
              <PageTitle breadcrumbs={FAQsBreadcrumbs}>Edit FAQ</PageTitle>
              <EditFAQ />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/faqs/all' />} />
    </Routes>
  )
}

export default FAQsPage
