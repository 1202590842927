/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {FAQ} from '../../core/_models'
import {useNavigate} from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {useQueryClient} from 'react-query'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import axios from 'axios'
import {QUERIES} from '../../../../../../../_metronic/helpers'

type Props = {
  faq: FAQ
}

const FAQActionsCell: FC<Props> = ({faq}) => {
  // const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  const MySwal = withReactContent(Swal)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  // const openEditModal = () => {
  //   setItemIdForUpdate(faq.id)
  // }

  // const deleteItem = useMutation(() => deleteFAQ(faq.id), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.FAQS_LIST}-${query}`])
  //   },
  // })

  const navigator = useNavigate()

  const deleteFAQ = () => {
    MySwal.fire({
      title: <p className='mb-0'>Are you sure you want to delete this FAQ?</p>,
      confirmButtonText: 'Yes',
      showDenyButton: true,
      denyButtonText: 'No',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-danger me-3',
        denyButton: 'btn btn-secondary',
      },
    }).then(({isConfirmed}) => {
      if (isConfirmed) {
        axios.delete(`/faq/${faq.id}`).then(() => {
          queryClient.invalidateQueries([`${QUERIES.FAQS_LIST}-${query}`])
        })
      }
    })
  }

  return (
    <>
      <div style={{marginBottom: '-8px'}}>
        <button
          className='btn btn-light btn-active-light-primary btn-sm mb-2 me-2'
          type='button'
          onClick={() => navigator(`/faqs/${faq.id}`)}
        >
          Edit
        </button>
        <button
          className='btn btn-light btn-active-light-primary btn-sm mb-2 me-2'
          type='button'
          onClick={deleteFAQ}
        >
          Delete
        </button>
      </div>
    </>
  )
}

export {FAQActionsCell}
