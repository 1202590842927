import axios from 'axios'
import React, {useEffect, useState} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {useNavigate} from 'react-router-dom'

const EditPrivacyPolicy = () => {
  const [value, setValue] = useState('')
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    axios.get('privacy-policy').then((response) => {
      setValue(response.data)
    })
  }, [])

  const submit = () => {
    setLoading(true)
    axios
      .post('update-privacy-policy', {
        html: value,
      })
      .then(async (response) => {
        // console.log(response)
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 1000)
      })
  }

  return (
    <div className='bg-white p-4'>
      <div className='row'>
        <div className='col-12'>
          <ReactQuill theme='snow' value={value} onChange={setValue} />
        </div>
        <div className='col-12 pt-4'>
          <button
            type='button'
            className='btn btn-primary me-4'
            onClick={submit}
            disabled={loading}
          >
            {!loading && <span className='indicator-label'>Save</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>

          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => navigate('/dashboard')}
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditPrivacyPolicy
