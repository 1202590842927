import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {EnquiriesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const ENQUIRY_URL = `${API_URL}/enquiry`
const GET_ENQUIRIES_URL = `${API_URL}/enquiry/list`

// axios.defaults.

const getEnquiries = (query: string): Promise<EnquiriesQueryResponse> => {
  return axios
    .get(`${GET_ENQUIRIES_URL}?${query}`)
    .then((d: AxiosResponse<EnquiriesQueryResponse>) => d.data)
}

const archiveEnquiry = (enquiryId: ID): Promise<void> => {
  return axios.put(`${ENQUIRY_URL}/${enquiryId}/archive`).then(() => {})
}

const archiveSelectedEnquiries = (enquiryIds: Array<ID>): Promise<void> => {
  const requests = enquiryIds.map((id) => axios.put(`${ENQUIRY_URL}/${id}/archive`))
  return axios.all(requests).then(() => {})
}

export {getEnquiries, archiveEnquiry, archiveSelectedEnquiries}
