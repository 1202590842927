/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  email: string
}

const EnquiryEmailCell: FC<Props> = ({email}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span>{email}</span>
    </div>
  </div>
)

export {EnquiryEmailCell}
