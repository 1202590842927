import {useEffect, useState} from 'react'
import {Ark, NftVersion, defaultCovers} from '../arks-list/core/_models'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {getArkById} from '../arks-list/core/_requests'
import {Swiper, SwiperSlide, SwiperClass} from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import {Controller} from 'swiper'
import axios from 'axios'
import {ArkInteractionlist} from './ArkInteractionList'
import {ArkPreview} from './ArkPreview'

const ArkDetails = () => {
  const params = useParams()
  const [ark, setArk] = useState<Ark | null>(null)
  const navigate = useNavigate()

  const [swiper, setSwiper] = useState<SwiperClass | null>(null)

  const [slides, setSlides] = useState<NftVersion[]>([])
  const [current, setCurrent] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    getArkById(params.id)
      .then((data) => {
        if (data) {
          setArk(data)
          setSlides([data.latest_version])
          setCurrent(data.latest_version.id)
        }
      })
      .catch(() => {
        navigate('/arks/all')
      })
  }, [navigate, params.id])

  const prevSlide = () => {
    if (swiper) {
      swiper.slidePrev()

      setTimeout(() => {
        setCurrent(slides[swiper.activeIndex].id)
      }, 1)
    }
  }

  const nextSlide = () => {
    if (swiper) {
      if (swiper.activeIndex === slides.length - 1) {
        setLoading(true)

        axios
          .get(`/nft/${ark?.id}/${current}`)
          .then((res) => {
            if (res?.data?.data) {
              setSlides([...slides, res.data.data])
            }

            setTimeout(() => {
              swiper.slideNext()
              setCurrent(res.data.data?.id)
            }, 1)
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        swiper.slideNext()

        setTimeout(() => {
          setCurrent(slides[swiper.activeIndex].id)
        }, 1)
      }
    }
  }

  return ark ? (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-200px symbol-fixed position-relative'>
                <img
                  src={ark.latest_version.thumbnail || defaultCovers[ark.category]}
                  className='object-fit-contain border'
                  alt='Ark'
                />
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-gray-800 fs-2 fw-bolder me-1'>
                      {ark.latest_version.title} (v{ark.version})
                    </span>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-2 pe-2'>
                    <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                      {ark.latest_version.description}
                    </span>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 pe-2'>
                    <span className='d-flex align-items-center text-gray-400 me-5'>
                      Owner:{' '}
                      <Link className='ms-2' to={`/users/${ark.user.id}`}>
                        {ark.user.full_name}
                      </Link>
                    </span>
                  </div>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-2 pe-2'>
                    <span className='d-flex align-items-center flex-wrap text-gray-400 me-5 mb-2'>
                      Collaborators:{' '}
                      {ark.collaborators.length
                        ? ark.collaborators.map((user, idx) => (
                            <div key={user.id}>
                              <Link className='ms-2' to={`/users/${user.id}`}>
                                {user.full_name}
                              </Link>
                              {idx !== ark.collaborators.length - 1 && ', '}
                            </div>
                          ))
                        : '-'}
                    </span>
                  </div>
                </div>
                <div className='mb-2'>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-2 pe-2'>
                    {/* <span
                      className={`d-flex align-items-center badge ${
                        ark.eShareType === 'public' ? 'badge-primary' : 'badge-danger'
                      } me-5 mb-2`}
                    >
                      {ark.eShareType}
                    </span> */}
                    Share Type: <span className='ms-2 text-capitalize'>{ark.share_type}</span>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{ark.interactions_count}</div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Interactions</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{ark.likes_count}</div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Likes</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{ark.shares_count}</div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Shares</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{ark.collab_requests_count}</div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Collab Requests</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>{ark.collaborators_count}</div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Collaborators</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='card mb-5 mb-xl-10'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-lg-6 '>
              <h3 className='mb-4'>Versions</h3>

              <div className='position-relative'>
                {loading && (
                  <div
                    className='position-absolute h-100 w-100 d-flex justify-content-center align-items-center'
                    style={{
                      backgroundColor: 'rgba(var(--bs-light-rgb), 0.5)',
                      zIndex: 99,
                    }}
                  >
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                )}

                <Swiper
                  modules={[Controller]}
                  spaceBetween={50}
                  slidesPerView={1}
                  noSwiping={true}
                  onSlideChange={(v) => {
                    setCurrent(slides[v.activeIndex].id)
                  }}
                  onSwiper={setSwiper}
                  lazyPreloadPrevNext={1}
                >
                  {slides.map((ark) => (
                    <SwiperSlide key={ark.id}>
                      <div style={{minHeight: '300px'}}>
                        <ArkPreview version={ark} />
                        {/* <img
                          className='border object-fit-contain mb-6'
                          width='100%'
                          height='400px'
                          src={ark.thumbnail || ark.cover_image || ark.file}
                          alt='ark'
                        /> */}
                        <p className='mb-0'>{ark.title}</p>
                        {ark.collaborators.length ? (
                          <p>
                            {ark.collaborators.map((c, i) => (
                              <span key={c.id}>
                                <Link to={`/users/${c.id}`}>{c.full_name}</Link>
                                {ark.collaborators.length !== i + 1 && <span>, {'  '}</span>}
                              </span>
                            ))}
                          </p>
                        ) : (
                          ''
                        )}
                        <span className='badge badge-primary mb-4'>{ark.version}</span>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>

              {swiper && (
                <div className='d-flex justify-content-between'>
                  <button
                    type='button'
                    disabled={loading || slides[swiper.activeIndex]?.id === ark?.latest_version.id}
                    className='btn btn-primary'
                    onClick={prevSlide}
                  >
                    Next
                  </button>
                  <button
                    type='button'
                    disabled={loading || slides[swiper.activeIndex]?.version === '1.0'}
                    className='btn btn-primary'
                    onClick={nextSlide}
                  >
                    Prev
                  </button>
                </div>
              )}
            </div>
            <div className='col-lg-6  border-start border-secondary'>
              <h3 className='mb-4'>Interactions</h3>

              <ArkInteractionlist ark={ark} />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>Loading...</div>
  )
}

export {ArkDetails}
