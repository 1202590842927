/* eslint-disable jsx-a11y/anchor-is-valid */
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import Pagination from '../../../../../../components/Pagination'

const FAQsListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({page, limit: pagination.limit || 10})
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end py-2'>
        <div id='kt_table_fAQs_paginate'>
          {pagination.total > pagination.limit && (
            <Pagination
              className='pagination-bar'
              currentPage={pagination.page}
              totalCount={pagination.total}
              pageSize={pagination.limit}
              onPageChange={updatePage}
              siblingCount={1}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export {FAQsListPagination}
