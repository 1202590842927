// @ts-nocheck
import {Column} from 'react-table'
import {ArkInfoCell} from './ArkInfoCell'
import {ArkSelectionCell} from './ArkSelectionCell'
import {ArkCustomHeader} from './ArkCustomHeader'
import {ArkSelectionHeader} from './ArkSelectionHeader'
import {Ark} from '../../core/_models'
import {ArkUploadedOnCell} from './ArkUploadedOnCell'
import {ArkInteractionsCell} from './ArkInteractionsCell'
import {ArkUserCell} from './ArkUserCell'
import {ArkCategoryCell} from './ArkCategoryCell'
// import {ArkActionsCell} from './ArkActionsCell'

interface PropsInterface {
  data: Ark[]
}

const arksColumns: ReadonlyArray<Column<Ark>> = [
  {
    Header: (props: PropsInterface) => <ArkSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}: PropsInterface) => <ArkSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props: PropsInterface) => (
      <ArkCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    id: 'title',
    Cell: ({...props}: PropsInterface) => <ArkInfoCell ark={props.data[props.row.index]} />,
  },
  {
    Header: (props: PropsInterface) => (
      <ArkCustomHeader tableProps={props} title='File type' className='min-w-125px' />
    ),
    // accessor: 'category',
    id: 'category',
    Cell: ({...props}: PropsInterface) => (
      <ArkCategoryCell category={props.data[props.row.index].category} />
    ),
  },
  {
    Header: (props: PropsInterface) => (
      <ArkCustomHeader tableProps={props} title='Uploaded by' className='min-w-125px' />
    ),
    id: 'user_id',
    Cell: ({...props}: PropsInterface) => <ArkUserCell user={props.data[props.row.index].user} />,
  },
  {
    Header: (props: PropsInterface) => (
      <ArkCustomHeader tableProps={props} title='Uploaded on' className='min-w-125px' />
    ),
    accessor: 'created_at',
    Cell: ({...props}: PropsInterface) => (
      <ArkUploadedOnCell date={props.data[props.row.index].created_at} />
    ),
  },
  {
    Header: (props: PropsInterface) => (
      <ArkCustomHeader tableProps={props} title='Version' className='min-w-125px' />
    ),
    accessor: 'version',
  },
  {
    Header: (props: PropsInterface) => (
      <ArkCustomHeader tableProps={props} title='Interaction' className='min-w-125px' />
    ),
    id: 'interactions_count',
    Cell: ({...props}: PropsInterface) => <ArkInteractionsCell ark={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props: PropsInterface) => (
  //     <ArkCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}: PropsInterface) => <ArkActionsCell ark={props.data[props.row.index]} />,
  // },
]

export {arksColumns}
