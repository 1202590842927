/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Enquiry} from '../../core/_models'

type Props = {
  enquiry: Enquiry
}

const EnquiryMessageCell: FC<Props> = ({enquiry}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span
        style={{
          whiteSpace: 'nowrap',
          width: '200px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {enquiry.text}
      </span>
    </div>
  </div>
)

export {EnquiryMessageCell}
